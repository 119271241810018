import './image-slider.scss';

import Swiper, { Navigation, Pagination, Parallax } from 'swiper';

import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/parallax';

class ImageSlider {
    constructor ($element) {
        this.$imageSlider = $element;
        this.$slidePrev = this.$imageSlider.querySelector('[data-image-slider="prev"]');
        this.$slideNext = this.$imageSlider.querySelector('[data-image-slider="next"]');
        this.imageSLider = undefined;
    }

    initialize () {
        this.initSlider();
    }

    initSlider () {
        this.imageSlider = new Swiper(this.$imageSlider, {
            modules: [Navigation, Pagination, Parallax],
            slidesPerView: 1,
            spaceBetween: 10,
            resistanceRatio: 0,
            parallax: true,
            speed: 1000,
            navigation: {
                prevEl: this.$slidePrev,
                nextEl: this.$slideNext
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'fraction'
            }
        });
    }
}

export { ImageSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $imageSlider = $context.querySelectorAll('[data-image-slider="root"]');

        for (let i = 0; i < $imageSlider.length; i++) {
            const $$imageSlider = new ImageSlider($imageSlider[i]);
            $$imageSlider.initialize();
        }
    }
});
